exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-agric-finance-index-jsx": () => import("./../../../src/pages/agric-finance/index.jsx" /* webpackChunkName: "component---src-pages-agric-finance-index-jsx" */),
  "component---src-pages-asset-finance-index-jsx": () => import("./../../../src/pages/asset-finance/index.jsx" /* webpackChunkName: "component---src-pages-asset-finance-index-jsx" */),
  "component---src-pages-auto-finance-index-jsx": () => import("./../../../src/pages/auto-finance/index.jsx" /* webpackChunkName: "component---src-pages-auto-finance-index-jsx" */),
  "component---src-pages-blog-[id]-index-jsx": () => import("./../../../src/pages/blog/[id]/index.jsx" /* webpackChunkName: "component---src-pages-blog-[id]-index-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-career-details-index-jsx": () => import("./../../../src/pages/career-details/index.jsx" /* webpackChunkName: "component---src-pages-career-details-index-jsx" */),
  "component---src-pages-careers-index-jsx": () => import("./../../../src/pages/careers/index.jsx" /* webpackChunkName: "component---src-pages-careers-index-jsx" */),
  "component---src-pages-carforcash-index-jsx": () => import("./../../../src/pages/carforcash/index.jsx" /* webpackChunkName: "component---src-pages-carforcash-index-jsx" */),
  "component---src-pages-comming-soon-index-jsx": () => import("./../../../src/pages/comming-soon/index.jsx" /* webpackChunkName: "component---src-pages-comming-soon-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-corporate-accounts-index-jsx": () => import("./../../../src/pages/corporate-accounts/index.jsx" /* webpackChunkName: "component---src-pages-corporate-accounts-index-jsx" */),
  "component---src-pages-csr-index-jsx": () => import("./../../../src/pages/csr/index.jsx" /* webpackChunkName: "component---src-pages-csr-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-fixed-deposit-index-jsx": () => import("./../../../src/pages/fixed-deposit/index.jsx" /* webpackChunkName: "component---src-pages-fixed-deposit-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-internet-banking-index-jsx": () => import("./../../../src/pages/internet-banking/index.jsx" /* webpackChunkName: "component---src-pages-internet-banking-index-jsx" */),
  "component---src-pages-ippis-loan-index-jsx": () => import("./../../../src/pages/ippis-loan/index.jsx" /* webpackChunkName: "component---src-pages-ippis-loan-index-jsx" */),
  "component---src-pages-leadership-[id]-index-jsx": () => import("./../../../src/pages/Leadership/[id]/index.jsx" /* webpackChunkName: "component---src-pages-leadership-[id]-index-jsx" */),
  "component---src-pages-leadership-index-jsx": () => import("./../../../src/pages/Leadership/index.jsx" /* webpackChunkName: "component---src-pages-leadership-index-jsx" */),
  "component---src-pages-leadership-management-[id]-jsx": () => import("./../../../src/pages/Leadership/management/[id].jsx" /* webpackChunkName: "component---src-pages-leadership-management-[id]-jsx" */),
  "component---src-pages-loans-index-jsx": () => import("./../../../src/pages/loans/index.jsx" /* webpackChunkName: "component---src-pages-loans-index-jsx" */),
  "component---src-pages-mt-green-solar-index-jsx": () => import("./../../../src/pages/mt-green-solar/index.jsx" /* webpackChunkName: "component---src-pages-mt-green-solar-index-jsx" */),
  "component---src-pages-mt-loans-index-jsx": () => import("./../../../src/pages/mt-loans/index.jsx" /* webpackChunkName: "component---src-pages-mt-loans-index-jsx" */),
  "component---src-pages-mtplus-loan-index-jsx": () => import("./../../../src/pages/mtplus-loan/index.jsx" /* webpackChunkName: "component---src-pages-mtplus-loan-index-jsx" */),
  "component---src-pages-mudarabah-index-jsx": () => import("./../../../src/pages/mudarabah/index.jsx" /* webpackChunkName: "component---src-pages-mudarabah-index-jsx" */),
  "component---src-pages-mutual-plus-loan-index-jsx": () => import("./../../../src/pages/mutual-plus-loan/index.jsx" /* webpackChunkName: "component---src-pages-mutual-plus-loan-index-jsx" */),
  "component---src-pages-page-404-index-jsx": () => import("./../../../src/pages/page-404/index.jsx" /* webpackChunkName: "component---src-pages-page-404-index-jsx" */),
  "component---src-pages-privacy-index-jsx": () => import("./../../../src/pages/privacy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-index-jsx" */),
  "component---src-pages-savings-account-index-jsx": () => import("./../../../src/pages/savings-account/index.jsx" /* webpackChunkName: "component---src-pages-savings-account-index-jsx" */),
  "component---src-pages-sign-in-index-jsx": () => import("./../../../src/pages/sign-in/index.jsx" /* webpackChunkName: "component---src-pages-sign-in-index-jsx" */),
  "component---src-pages-sme-loans-index-jsx": () => import("./../../../src/pages/sme-loans/index.jsx" /* webpackChunkName: "component---src-pages-sme-loans-index-jsx" */),
  "component---src-pages-tenhours-index-jsx": () => import("./../../../src/pages/tenhours/index.jsx" /* webpackChunkName: "component---src-pages-tenhours-index-jsx" */),
  "component---src-pages-treasury-note-index-jsx": () => import("./../../../src/pages/treasury-note/index.jsx" /* webpackChunkName: "component---src-pages-treasury-note-index-jsx" */),
  "component---src-pages-who-we-are-index-jsx": () => import("./../../../src/pages/who-we-are/index.jsx" /* webpackChunkName: "component---src-pages-who-we-are-index-jsx" */)
}

